.chk input {
    display: none;
}

.chk span {
    width: 20px;
    height: 20px;
    display: block;
    background-color: rgb(0, 0, 0, 0);
    border: 2px solid #FFF;
}

.chk input:checked + span {
    color: #FFF;
    background-image: url('../../../Icons/check.png');
    background-position: 50% 50%;
    background-size: cover;
}

[tooltip]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 1s;
    visibility: visible;
  }

  [tooltip]:after {
    content: attr(tooltip);
    position: absolute;
    border-radius: 2px;
    background-color: #575859;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.34);
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    padding: 4px 8px;
    white-space: nowrap;
    opacity: 0;
    z-index: 999999;
    visibility: hidden;
    left: 1.4rem;
    bottom: -1.5rem;
  }

  [tooltip] {
    position: relative;
  }
